// Handles URL switching based on dropdown options on the multiregion homepage map
document.body.addEventListener("click", function (e) {
    const countrySelector = document.querySelector("#homepageMap-dropdownList");
    if (countrySelector) {
        const homePageMapToggleIcon = document.querySelector(
            "#homepageMap-toggleIcon"
        );
        if (countrySelector.offsetParent) {
            let rect = countrySelector.getBoundingClientRect();
            let clickedIn =
                rect.top <= e.clientY &&
                e.clientY <= rect.top + rect.height &&
                rect.left <= e.clientX &&
                e.clientX <= rect.left + rect.width;

            if (!clickedIn) {
                if (homePageMapToggleIcon) {
                    homePageMapToggleIcon.classList.toggle(
                        "a-dropdownLink--icon--toggled"
                    );
                }
                jQuery("#homepageMap-dropdownList").fadeToggle();
            }
        } else {
            const countrySelectorDropdown = document.querySelector(
                "#homepageMap-dropdownMenu"
            );
            if (countrySelectorDropdown) {
                if (countrySelectorDropdown.offsetParent) {
                    let rect = countrySelectorDropdown.getBoundingClientRect();
                    let clickedIn =
                        rect.top <= e.clientY &&
                        e.clientY <= rect.top + rect.height &&
                        rect.left <= e.clientX &&
                        e.clientX <= rect.left + rect.width;
                    if (clickedIn) {
                        if (homePageMapToggleIcon) {
                            homePageMapToggleIcon.classList.toggle(
                                "a-dropdownLink--icon--toggled"
                            );
                        }
                        jQuery("#homepageMap-dropdownList").fadeToggle();
                    }
                }
            }
        }
    }
});
