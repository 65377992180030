// Brand slider scripting
document.addEventListener("DOMContentLoaded", function () {
    const mLogoSliders = Array.from(document.querySelectorAll(".m-logoSlider"));
    if (mLogoSliders.length > 0) {
        mLogoSliders.forEach((mLogoSlider) => {
            mLogoSlider.classList.remove("--preload");
        });
        jQuery(".m-logoSlider").slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 0,
            buttons: false,
            cssEase: "linear",
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 8000,
            useTransform: false,
            variableWidth: true,
        });
    }
});
