// Controls the switching of images shown in the product spotlight module
document.addEventListener("DOMContentLoaded", function () {
    const spotlightSliderThumbnails = Array.from(
        document.querySelectorAll(".m-spotlightSlider__thumbnail")
    );

    if (spotlightSliderThumbnails.length > 0) {
        spotlightSliderThumbnails.forEach((spotlightSliderThumbnail) => {
            spotlightSliderThumbnail.addEventListener("click", () => {
                let spotlightCurrent = spotlightSliderThumbnail.dataset.img;
                const currentSpotlightSliderThumbnail = document.querySelector(
                    ".m-spotlightSlider__thumbnail--current"
                );
                const currentSpotlightSliderImage = document.querySelector(
                    ".m-spotlightSlider__image--current"
                );

                currentSpotlightSliderThumbnail.classList.remove(
                    "m-spotlightSlider__thumbnail--current"
                );
                currentSpotlightSliderImage.classList.remove(
                    "m-spotlightSlider__image--current"
                );

                spotlightSliderThumbnail.classList.add(
                    "m-spotlightSlider__thumbnail--current"
                );
                document
                    .querySelector(`#${spotlightCurrent}`)
                    .classList.add("m-spotlightSlider__image--current");
            });
        });
    }
});
